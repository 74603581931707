.floating-vc {
    position: fixed;
    bottom: 5%;
    right: 1.2%;
    border: 1px solid #b8b8b8;
    background-color: #ffffff;
    width: 40%;
    height: 200px;
    z-index: 10;
    box-shadow: 0 0 4px 0 #ababab;
}
.floating-vc.big-screen {
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
}
.floating-vc.hidden {
    display: none;
}
.fvc-container {
    position: relative;
    width: 100%;
    height: 100%;
}
.half-wh-elem {
    width: 100%;
    height: 100%;
}

.floating-vc .fvc-widget {
    background: url('../../assets/images/electronics.svg') #DFDFDF center no-repeat;
    background-size: 5vw;
}

.floating-vc .fvc-widget.publisher-elem {
    /* top: 1% !important;
    right: 1%;
    width: 100px; */
    /* position: absolute; */
    /* width: 50%; */
    background-color: #383838;
    box-shadow: none;
    border: none;
    background: url('../../assets/images/electronics.svg') #ebebeb no-repeat center;
    background-size: 5vw;
    /* background-image: url('/assets/images/publisher-poster.png');
    background-repeat: no-repeat;
    background-position: 0 20%;
    background-size: cover; */
    border-radius: 0;
}

.floating-vc .video-call-widget-vffull {
    /* background: url('../../assets/images/electronics.svg') #DFDFDF center no-repeat;
    background-size: 100px; */
    /* background-image: url('/assets/images/publisher-poster.png');
    background-repeat: no-repeat;
    background-position: 0 30%;
    background-size: cover; */
}
.fvc-widget {
    float: left;
    width: 50%;
    height: 100%;
}

.fvc-widget:first-child {
    border-right: 1px solid #fff;
}

.vc-back-btn {
    /* background-color: #ffffff;
    border-radius: 100px;
    border: 2px solid #ccc; */
}
.vc-back-btn img {
    width: 2.3vw;
}
#main_video { width: 100%; height: 100%; }
.floating-vc.hidden.big-screen {display: block !important;}
#consult_subscriber_container { width: 100%; height: 100%; }
#consult_publisher, #consult_subscriber, #float_publisher, #float_subscriber { width: 100%; height: 100%; }
.OT_edge-bar-item { display: none !important; }