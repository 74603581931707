

  .otpinp_wrapper > div {
    display: inline-flex !important;
  }

  .otpinp_wrapper > div input {
    height: 41px !important;
    width: 70px !important;
    margin: 0 20px;
    border: 0;
    font-size: 25px;
    border-bottom: 2px solid #d34350;
    outline: none;
}

.bold-text{
  font-weight: 400 !important;
}