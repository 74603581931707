.incoming-call-widget { 
    position: fixed; 
    right: 3%;
    top: 5%; 
    background-color: #ffffff; 
    box-shadow: 0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16);
    border: 1px solid #e6e6e6;
    width: 350px;
    z-index: 100;
}
.call-header { padding: 10px 15px; border-bottom: 1px solid #e6e6e6; }
.call-body {
    padding: 10px 15px;
}
.call-body .media-body h5 {
    font-size: 1rem;
    margin-bottom: 0;
}
.close-inccall {
    cursor: pointer;
    padding: 2px 7px;
}
.close-inccall:hover {
    background-color: #e7e7e7;
}
.call-footer {
    padding: 0 15px 15px 62px;
    /* border-top: 1px solid #e6e6e6; */
}
.call-footer button {
    margin-right: 10px;
    width: 80px;
}
.closingin {
    font-size: 12px;
    margin-top: 4px;
    /* color: #ccc; */
}