

.App-link {
  color: #61dafb;
}

.login{
  background-color: #d34350 !important;
  border: solid 1px #d34350 !important;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}