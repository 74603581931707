/* html,body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
.virtual-name{
  color: #d24350;
  font-weight: 600;
}
.blanks{
  width: 72px;
  height: 42px;
  text-align: center;
  margin-right: 20px;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid  #d34350;
  border-left: none;
  border-image: initial;
  color: rgb(18, 18, 19);
  border-radius: 0px;
  background: rgb(255, 255, 255);
  font-size: 22px;
}
.Resend-otp{
  color: #d34350;
  cursor: pointer;
} 